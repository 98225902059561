import React, { useState } from "react";
import './App.css';
import './/components/img/clients-and-selectors-icon.svg';
import './components/img/LOGO.png';
import './components/img/menu.svg';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import EventSelector from "./components/EventSelector";
import Contracts from "./components/Contracts";
import Plans from "./components/Plans";
import Reports from "./components/Reports";
import ClientsAndSelectors from "./components/ClientsAndSelectors";
import EventMatcher from "./components/EventMatcher";
import EventJournal from "./components/EventJournal";
import Login from "./Login";
import {Button} from "@mui/material";
import Cookies from "js-cookie";
import PlanCreate from "./components/PlanCreate";
import Boxes from "./components/Boxes";
import {Link} from "react-router-dom";
import Test from "./components/Test"; ///////////////////////////////////////////////////////
import Companies from "./components/Companies";
import Users from "./components/Users";
import Marks from "./components/Marks";
import Locations from "./components/Locations";

function App({onAuth}) {
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapsed = () => {
      setCollapsed(!collapsed);
      if (!collapsed) {
          document.getElementsByClassName('sidebar-button')[0].style.transform = 'translateX(-180px)';
      }
      else {
          document.getElementsByClassName('sidebar-button')[0].style.transform = 'translateX(0)';
      }
    };

    const [visible, setVisible] = useState('event-journal');

    const showEventSelector = () => {
        setVisible('companies');
    };

    const showRegisterPlans = () => {
        setVisible('Registerplans');
    };
    const showPlans = () => {
        setVisible('plans');
    };
    const showReports = () => {
        setVisible('reports');
    }

    const showClientsAndSelectors = () => {
        setVisible('clients-and-selectors');
    }

    const showEventMatcher = () => {
        setVisible('event-matcher')
    }

    const showEventJournal = () => {
        setVisible('event-journal')
    }

    const showLogin = () => {
        setVisible('users')
    }
    const showContracts = () => {
        setVisible('contracts')
    }
    const showBoxes = () => {
        setVisible('boxes')
    }
    const showMarks = () => {
        setVisible('marks')
    }
    const showLocations = () => {
        setVisible('locations')
    }
    const logout = () => {
        Cookies.remove('jwtToken');
        localStorage.removeItem('jwtToken');
        onAuth(false);
    }

  return (
    <div className="App">
        <div className={'header'}>
            <img src={require('./components/img/LOGO.png')}/>
            <Link to="/login" onClick={logout}>
                <Button>Выйти из системы</Button>
            </Link>
        </div>
        <div className={'main-content'}>
          <Sidebar collapsed={collapsed} className={'sidebar'}>
            <Menu>
                <button className={'sidebar-button'} onClick={toggleCollapsed}>
                    <img src={require('.//components/img/menu.svg').default} alt={''}/>
                </button>
              {/* <MenuItem icon={<img src={require('.//components/img/clients-and-selectors-icon.svg').default} alt={''}/>}
                  onClick={showClientsAndSelectors}>
                  Клиенты и селекторы </MenuItem> */}
              <MenuItem onClick={showEventSelector} className={'event-selector-menu'}
              icon={<img src={require('.//components/img/companies-icon.svg').default} alt={''}/> }>
                  Компании</MenuItem>
              <MenuItem onClick={showLogin} icon={<img src={require('.//components/img/user-icon.svg').default} alt={''}/>}>
                  Пользователи </MenuItem>
              {/* <MenuItem icon={<img src={require('.//components/img/apps.svg').default} alt={''}/>}>
                  Группы </MenuItem> */}
              <MenuItem onClick={showBoxes} icon={<img src={require('.//components/img/hardware-boxes-icon.svg').default} alt={''}/> }>
                  Аппаратные коробочки </MenuItem>
              <MenuItem  onClick={showMarks} icon={<img src={require('.//components/img/marks-icon.svg').default} alt={''}/> }>
                  Метки </MenuItem>
              <MenuItem onClick={showLocations} icon={<img src={require('.//components/img/locations-icon.svg').default} alt={''}/> }>
                  Локации </MenuItem>
              <MenuItem onClick={showContracts} icon={<img src={require('.//components/img/contracts-icon.svg').default} alt={''}/> }>
                  Контракты </MenuItem>
                <hr className={'sidebar-line'}/>
              <MenuItem onClick={showEventJournal}> Журнал событий </MenuItem>
              <MenuItem onClick={showRegisterPlans}> Регистрация планов </MenuItem>
              <MenuItem onClick={showPlans}> Планы </MenuItem>
              <MenuItem onClick={showReports}> Отчеты </MenuItem>
              <MenuItem onClick={showEventMatcher}> Автоанализ отчетов </MenuItem>
            </Menu>
          </Sidebar>
            {visible === 'event-selector' && <EventSelector />}
            {visible === 'Registerplans' && <Test />}
            {visible === 'plans' && <Plans />}
            {visible === 'reports' && <Reports />}
            {visible === 'clients-and-selectors' && <ClientsAndSelectors />}
            {visible === 'event-matcher' && <EventMatcher />}
            {visible === 'event-journal'  && <EventJournal />}
            {visible === 'contracts'  && <Contracts />}
            {visible === 'boxes'  && <Boxes />}
            {visible === 'companies'  && <Companies />}
            {visible === 'users'  && <Users />}
            {visible === 'marks'  && <Marks />}
            {visible === 'locations'  && <Locations />}
        </div>
    </div>
  );
}
export default App;
