import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import App from './App';
import Login from './Login';
import Cookies from 'js-cookie';

const Auth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handleAuth = (authChange) => {
        setIsAuthenticated(authChange);
    };

    useEffect(() => {
        let jwtToken = Cookies.get('jwtToken');

        if (jwtToken) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    return (
        <Router>
            <nav className={'links'}>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/login">Login</Link>
                    </li>
                </ul>
            </nav>

            <Routes>
                <Route path="/login" element={!isAuthenticated ? <Login onAuth={handleAuth} /> : <Navigate to='/' />} />
                <Route path="/" element={isAuthenticated ? <App onAuth={handleAuth} /> : <Navigate to="/login" />} />
            </Routes>
        </Router>
    );
};

export default Auth;