import React from 'react';
import ReactDOM from 'react-dom/client';
import Auth from "./Auth";
import App from "./App";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <div>
    <Auth />
    </div>
);

