import React from 'react';
import {Button} from "@mui/material";
import DateConverter from "../scripts/DateConverter";
import Cookies from 'js-cookie';

const EventJournal = () => {
    async function loadJournal() {
        let promise = await fetch('http://box-dev.dvlb.ru:8081/get_data', {
            headers: {
                "Access-Control-Allow-Origin": 'http://box-dev.dvlb.ru:8081',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
        });
        if (promise.ok) {
            let journalData = await promise.json();
            let current_table = document.querySelectorAll('tr');
            for (let i of current_table){
                i.remove();
            }
            for (let i = 0; i < journalData.length; i++) {
                let rowCell = document.createElement('tr');
                rowCell.classList.add('journal-table-row');
                document.getElementsByClassName('journal-table')[0].appendChild(rowCell);
                for (let j = 0; j <= 3; j++) {
                    let columnCell = document.createElement('td');
                    columnCell.classList.add('journal-table-column');
                    document.getElementsByClassName('journal-table-row')[i].appendChild(columnCell);
                    if (j === 0) {
                        columnCell.append(journalData[i].box_id);
                    }
                    else if (j === 1) {
                        columnCell.append(journalData[i].mark_id);
                    }
                    else if (j === 2) {
                        columnCell.append(DateConverter(journalData[i].event_time));
                    }
                    else if (j === 3) {
                        columnCell.append(DateConverter(journalData[i].date));
                    }
                }
            }
        }
    }
    return (
        <div className={'screen-content'}>
            <p className={'journal-header'}>Журнал событий</p>
            <p className={'journal-data'}></p>
            <Button onClick={loadJournal}>Вывести данные</Button>
            <table className={'journal-table'}></table>
        </div>
    );
};

export default EventJournal;