import React, {useState} from 'react';
import {Button, Checkbox} from "@mui/material";
import './img/menu.svg';
import FilterSidebar from "./FilterSidebar";

const ClientsAndSelectors = () => {

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        }}>
            <div className={'screen-content'}>
                <p className={'selectors-header'}>Клиенты и селекторы</p>
                <div className={'action-selector'}>
                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        className={'action-selector-btn'}>
                        Действие 1</Button>
                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        className={'action-selector-btn'}>
                        Действие 2</Button>
                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        className={'action-selector-btn'}>Действие 3</Button>
                </div>
                <table>
                    <tr>
                        <th className={'cell'}><Checkbox /></th><th>Header 1</th><th>Header 2</th><th>Header 3</th>
                    </tr>
                    <tr>
                        <td><Checkbox /></td><td>Cell A</td><td>Cell B</td><td>Cell C</td>
                    </tr>
                    <tr>
                        <td><Checkbox /></td><td>Cell A</td><td>Cell B</td><td>Cell C</td>
                    </tr>
                    <tr>
                        <td><Checkbox /></td><td>Cell A</td><td>Cell B</td><td>Cell C</td>
                    </tr>
                    <tr>
                        <td><Checkbox /></td><td>Cell A</td><td>Cell B</td><td>Cell C</td>
                    </tr>
                    <tr>
                        <td><Checkbox /></td><td>Cell A</td><td>Cell B</td><td>Cell C</td>
                    </tr>
                </table>
            </div>
                <FilterSidebar/>
        </div>

    );
};

export default ClientsAndSelectors;