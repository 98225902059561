import React, {useRef, useState} from 'react';
import {Button, FormControl, FormControlLabel, TextField} from "@mui/material";
import './components/img/LOGO.png';
import Cookies from 'js-cookie';
import {Link} from "react-router-dom";

const Login = ({onAuth}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const Authorize = () => {
        let jwt_token = '';
        const promise = fetch('http://185.241.68.155:8081/token', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username: username, password: password })
        })
            .then(data => data.json())
            .then(async (data) => {
                jwt_token = data.access_token;
                Cookies.set('jwtToken', jwt_token);
                console.log(jwt_token);
                await onAuth(true);
            }).catch(error =>{
            setIsPasswordIncorrect(true);
        })
    };

    return (
        <div className={'login-background'}>
            <form className={'login-form'}>
                <img src={require('./components/img/LOGO.png')} alt="Logo" />
                <h1>Авторизация</h1>
                <FormControl>
                    <TextField
                        id={'username'}
                        label={'Логин'}
                        value={username}
                        onChange={handleUsernameChange}/>
                    <TextField
                        error={isPasswordIncorrect}
                        id={'password'}
                        label={'Пароль'}
                        type={'password'}
                        value={password}
                        onChange={handlePasswordChange}
                        sx={{ marginTop: '15px' }}/>
                    <Link onClick={Authorize} to={'/'}>
                        <Button  sx={{marginTop: '15px'}} variant={'outlined'}>
                            Отправить
                        </Button>
                    </Link>
                </FormControl>
            </form>
        </div>
    );
};

export default Login;