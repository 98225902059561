import React, {useState} from 'react';
// import {Button, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, Paper} from "@mui/material";
// import DatePicker from "react-datepicker";
// import DateConverter from "../scripts/DateConverter";
import Cookies from "js-cookie";
import FilterSidebar from "./FilterSidebar";
import "react-datepicker/dist/react-datepicker.css";
import DateConverter from "../scripts/DateConverter";
const FileDownload = require("js-file-download");
const Plans = () => {

    function checkEvents(events){
        
        let result = events.length > 0;
        console.log(result);
        if (!result){
            return 'red'
        }
        result = false;
        for (let i of events){
            result = result || i.is_target_worker
        }
        return result ? 'green' : 'yellow'


    }


    fetch('http://box-dev.dvlb.ru:8081/plans',{
            headers: {
                "Access-Control-Allow-Origin": 'http://box-dev.dvlb.ru:8081',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
        }).then(response =>{if (response.status !== 200){
            throw new Error('response.statusCode');
        };
    return response;}).then(response => response.json()).then(response=>{
            let container = document.querySelector('.plans-container');
            container.innerHtml = '';
            for(let i of response){
                let plan = document.createElement('div');
                plan.classList.add('plan');
                let name = document.createElement('div');
                let button_container = document.createElement('div');
                button_container.classList.add('button-container');
                let upd = document.createElement('div');
                let del = document.createElement('div');
                let match = document.createElement('div');
                name.classList.add('plan-name');
                upd.classList.add('plan-button','plan-update');
                del.classList.add('plan-button','plan-delete');
                match.classList.add('plan-button','plan-match')
                name.innerText = i.name;
                upd.innerText = 'Обновить';
                del.innerText = 'Удалить';
                match.innerText = 'Отчет';
                plan.appendChild(name);
                button_container.appendChild(upd);
                button_container.appendChild(del);
                button_container.appendChild(match);
                plan.appendChild(button_container);
                console.log(i.status);
                console.log(new Date().getTime());
                plan.style.backgroundColor = i.status ? '#f48a8a' :  '#8cf18c' ;
                if (i.time_start > new Date().getTime()/1000){
                    plan.style.backgroundColor = '#fffd8d';
                }
                container.appendChild(plan);
                match.addEventListener('click', ()=>{
                    fetch('http://185.241.68.155:8081/plans/match/' + i.name, {
                headers: {
                    "Access-Control-Allow-Origin": 'http://185.241.68.155:8081',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                }
            }).then(response=>{
                if(response.status !== 200){
                    throw new Error(response.status);
                }
                return response
            }).then(response=>response.json()).then(response=>{
                    let color = plan.style.backgroundColor 
                    // let height = plan.style.height;
                    let direction = plan.style.flexDirection;
                    plan.style.backgroundColor = 'white';
                    plan.style.minHeight = '700px';
                    plan.style.flexDirection = 'column';
                    button_container.style.width = '100%';


                    let table_container = document.createElement('div');
                    table_container.classList.add('table-container');
                    let table = document.createElement('table');
                    table.classList.add('table');
                    table_container.appendChild(table);
                    plan.appendChild(table_container);
                    const reset_button = document.createElement('div');
                    reset_button.innerText = 'Свернуть';
                    reset_button.classList.add('plan-button');
                    
                    const excel_button = document.createElement('div');
                    excel_button.innerText = 'Выгрузить';
                    excel_button.classList.add('plan-button');
                    excel_button.addEventListener('click',()=>{
                        fetch(
                            `http://box-dev.dvlb.ru:8081/reports/excel/${response.id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": 'http://box-dev.dvlb.ru:8081',
                            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                        }
                    }).then(data => data.blob()).then(data => {FileDownload(data, `${response.name}_${DateConverter(response.time)}.xlsx`)});
                    });


                    reset_button.addEventListener('click',()=>{
                        table_container.remove();
                        plan.style.backgroundColor = color;
                        plan.style.minHeight = '70px';
                        plan.style.flexDirection = direction;
                        reset_button.remove();
                        excel_button.remove();
                        button_container.style.width = '50%';
                    });
                    button_container.appendChild(excel_button);
                    button_container.appendChild(reset_button);


                    let planData = response;

            let time_start = planData.time_start;
            let time_stop = planData.time_stop;
            console.log('1');
            // let delta = planData.time_stop - planData.time_start;
            let current_time = time_start
            // create date row
            let row = document.createElement('tr');
            row.classList.add('matcher-table-row');
            let clean_space = document.createElement('td');
            row.appendChild(clean_space);
            let dates = []; 
            while(current_time <= time_stop){
                let day = current_time;
                let dateElement = document.createElement('td');
                dateElement.innerText = DateConverter(day);
                row.appendChild(dateElement);
                dates.push(current_time);
                current_time += 3600*24;
                
            }
            console.log('2');
            table.appendChild(row);
            // generate data rows/cols
            planData = planData.plan;
            let locationsCount = planData.length;
            for (let i = 0; i < locationsCount; i++){
                let row = document.createElement('tr');
                for (let j = 0; j <= dates.length; j++){
                    let part = document.createElement('td');
                    if (j == 0){
                        part.innerText = planData[i].location;
                    }else{
                        if(planData[i].dates.includes(dates[j-1])){
                            let container = document.createElement('div');
                            container.classList.add('table_container', `${i}`, `${j-1}`);
                            container.innerText = `${DateConverter(dates[j-1] + planData[i].start)} - ${DateConverter(dates[j-1] + planData[i].finish)}`;
                            container.addEventListener('click',()=>{
                                
                                let modal_container = document.createElement('div');
                                modal_container.classList.add('modal_container','notransition');
                                let modal = document.createElement('div');
                                modal.classList.add('modal', 'notransition');
                                let eventsData = [];
                                planData[i].events[dates[j-1]].forEach((item)=>{
                                    eventsData.push({box_id: item.box_id, event_time: DateConverter(item.event_time), mark_id : item.mark_id});
                                });
                                console.log(eventsData);
                                // modal.innerHTML = `
                                // <div>Назначенные пользователи - ${planData[i].users}</div>
                                // <div>Назначенные группы пользователей - ${planData[i].groups}</div>
                                // <div>Назначенные события - ${eventsData}</div>
                                // `
                                let modalTable = document.createElement('table');
                                let modalTableRow = document.createElement('tr');
                                let name_td = document.createElement('td');
                                name_td.innerText = 'Назначенные пользователи';
                                let users_td = document.createElement('td');
                                users_td.innerText = `${planData[i].users}`;
                                modalTableRow.appendChild(name_td);
                                modalTableRow.appendChild(users_td);
                                modalTable.appendChild(modalTableRow);


                                modalTableRow = document.createElement('tr');
                                name_td = document.createElement('td');
                                name_td.innerText = 'Назначенные группы пользователей';
                                users_td = document.createElement('td');
                                users_td.innerText = `${planData[i].groups}`;
                                modalTableRow.appendChild(name_td);
                                modalTableRow.appendChild(users_td);
                                modalTable.appendChild(modalTableRow);
                                

                                modalTableRow = document.createElement('tr');
                                name_td = document.createElement('td');
                                name_td.innerText = 'Зарегистрированные события';
                                users_td = document.createElement('td');

                                eventsData.forEach((item)=>{
                                    let row = document.createElement('tr');
                                    Object.keys(item).forEach((key)=>{
                                        let column = document.createElement('td');
                                        column.innerText = item[key];
                                        row.appendChild(column);
                                    });
                                    users_td.appendChild(row);
                                });

                                modalTableRow.appendChild(name_td);
                                modalTableRow.appendChild(users_td);
                                modalTable.appendChild(modalTableRow);


                                modal.appendChild(modalTable);
                                modal_container.appendChild(modal);
                                modal_container.addEventListener('click', (e)=>{
                                    if (e.target.classList.contains('modal_container')){
                                        modal_container.remove();
                                    }
                                });
                                document.body.appendChild(modal_container);

                            });
                        console.log(planData[i].events[dates[j-1]]);

                        part.style.backgroundColor = checkEvents(planData[i].events[dates[j-1]]);


                        part.appendChild(container);
                    }
                        }
                        row.appendChild(part);
                    }
                    table.appendChild(row);
                }
                    
            }).catch(error=>{console.log(error);});
                });
                
            }
            
        }
        ).catch(error => console.log(error));

    return (
        <div className={'screen-container'}>
            <div className={'plans-container'}></div>
            <FilterSidebar/>
        </div>
        
    );
};

export default Plans;