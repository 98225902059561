import React, {useState, useRef} from 'react';
import {Button, TextField} from "@mui/material";

const EventSelector = () => {
    const [text, setText] = useState('');
    const inputRef = useRef(null);
    const handleInputChange = (event) => {
        setText(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleButtonClick();
        }
    };

    const handleButtonClick = () => {
        console.log(text);
        setText('');
        inputRef.current.value = '';
    };
    return (
        <div className={'screen-content'}>
            <p> JUST A TEST SCREEN NEVERMIND</p>
            <TextField
                ref={inputRef}
                label={'Type smth'}
                value={text}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                className={'some-input'}
                sx={{margin:'20px'}}></TextField>
            <Button
                className={'some-button'}
                sx={{margin:'20px'}}
                onClick={handleButtonClick}>Send</Button>
        </div>
    );
};

export default EventSelector;