import React, {useState} from 'react';
import {Menu, MenuItem, Sidebar} from "react-pro-sidebar";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    Radio,
    RadioGroup,
    Select
} from "@mui/material";

const FilterSidebar = () => {
    const [filterCollapsed, setFilterCollapsed] = useState(false)

    const toggleFilterCollapsed = () => {
        setFilterCollapsed(!filterCollapsed)
    }
    return (
        <div>
            <Sidebar className={'filter-sidebar-container'} collapsed={filterCollapsed} collapsedWidth={'0px'} width={'300px'}>
                <Menu className={'filter-sidebar'}>
                    <button id={'filter-sidebar-slide-off'} className={'sidebar-button'} onClick={toggleFilterCollapsed}>
                        <img src={require('./img/menu.svg').default} alt={''}/>
                    </button>
                    <p>Тип фильтрации</p>
                    <FormControl fullWidth sx={{marginBottom: '15px'}}>
                        <InputLabel id="demo-simple-select-label">Отбор А</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Отбор А">
                            <MenuItem value={10}>Критерий А</MenuItem>
                            <MenuItem value={20}>Критерий В</MenuItem>
                            <MenuItem value={30}>Критерий С</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{marginBottom: '15px'}}>
                        <InputLabel id="demo-simple-select-label">Отбор А</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Отбор А">
                            <MenuItem value={10}>Критерий А</MenuItem>
                            <MenuItem value={20}>Критерий В</MenuItem>
                            <MenuItem value={30}>Критерий С</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{marginBottom: '15px'}}>
                        <InputLabel id="demo-simple-select-label">Отбор А</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Отбор А">
                            <MenuItem value={10}>Критерий А</MenuItem>
                            <MenuItem value={20}>Критерий В</MenuItem>
                            <MenuItem value={30}>Критерий С</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel> Тип фильтрации </FormLabel>
                        <RadioGroup defaultValue={'full'}>
                            <FormControlLabel
                                control={<Radio />}
                                value={'full'}
                                label={"Единичный фильтр"}>
                            </FormControlLabel>
                            <FormControlLabel
                                control={<Radio />}
                                value={'reduced'}
                                label={'Единичный фильтр'}>
                            </FormControlLabel>
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel> Тип фильтрации </FormLabel>
                        <RadioGroup defaultValue={'full'}>
                            <FormControlLabel
                                control={<Checkbox />}
                                value={'full'}
                                label={"Множественный фильтр"}>
                            </FormControlLabel>
                            <FormControlLabel
                                control={<Checkbox />}
                                value={'reduced'}
                                label={'Множественный фильтр'}>
                            </FormControlLabel>
                        </RadioGroup>
                    </FormControl>
                    <Button>Применить</Button>
                    <Button>Сбросить</Button>
                </Menu>
            </Sidebar>
            <button className={'sidebar-button'} id={'filter-sidebar-slide-on'} onClick={toggleFilterCollapsed}>
                <img src={require('./img/menu.svg').default} alt={''}/>
            </button>
        </div>
    );
};

export default FilterSidebar;