import React from 'react';

const EventMatcher = () => {
    return (
        <div className={'screen-content'}>
            <p className={'matcher-header'}>Автоанализ отчетов</p>
        </div>
    );
};

export default EventMatcher;