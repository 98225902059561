import React from 'react';

import Cookies from 'js-cookie';

const Boxes = () => {

    fetch('http://box-dev.dvlb.ru:8081/boxes',{
            headers: {
                "Access-Control-Allow-Origin": 'http://box-dev.dvlb.ru:8081',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
        }).then(response =>{if (response.status !== 200){
            throw new Error('response.statusCode');
        };
    return response;}).then(response => response.json()).then(response=>{
            let container = document.querySelector('.screen-content');
            let table = document.querySelector('.journal_table');
            if (table){
                table.innerHtml = '';
            }else{
                table = document.createElement('table');
                table.classList.add('.journal-table');
            }
            
            let header = document.createElement('tr');
            const table_header = {
                'Идентификатор коробочки':'box_id',
                'Имя пользователя':"user_id",
                'Тип устройства':'is_soft',
                'Активность':'is_active'
            };
            for(let i of Object.keys(table_header)){
                let td = document.createElement('th');
                td.innerText = i;
                header.appendChild(td);
            }
            table.appendChild(header);
            for (let i of response){
                let row = document.createElement('tr');
                const check_keys={
                    'Тип устройства':{
                        false:'Аппаратная коробочка',
                        true:'Мобильное приложение'
                    },
                    'Активность':{
                        true:'Активна',
                        false:'Не активна'
                    }
                };
                for(let j of Object.keys(table_header)){
                    let td = document.createElement('td');
                    
                    if (Object.keys(check_keys).includes(j)){
                        td.innerText = check_keys[j][i[table_header[j]]];
                        row.appendChild(td);
                    }else{
                    td.innerText = i[table_header[j]];
                    row.appendChild(td);}
                }
                table.appendChild(row);
            }
            container.appendChild(table);
        }
        ).catch(error => console.log(error));
        
    return (
        <div className={'screen-content'}>
            {/* <p className={'journal-header'}>Журнал событий</p>
            <p className={'journal-data'}></p>
            <Button onClick={loadJournal}>Вывести данные</Button>
            <table className={'journal-table'}></table> */}
        </div>
    );
};

export default Boxes;