import React, {useEffect, useState} from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Container,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Modal,
    Typography,
    Checkbox
} from '@mui/material';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Cookies from "js-cookie";

function PlanForm() {
  const [name, setName] = useState('');
  const [timeZone, setTimeZone] = useState('UTC+2');
  const [timeStart, setTimeStart] = useState(null);
  const [timeStop, setTimeStop] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [locID, setLocID] = useState('');
  const [users, setUsers] = useState('');
  // const [eventStart, setEventStart] = useState(null);
  // const [eventStop, setEventStop] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [waypts, setWaypts] = useState([]);
  const [shouldRequestSent, setshouldRequestSent] = useState(false);
  // setWaypts([...waypts, item])
    let planData = {
            name: name,
            time_zone: timeZone,
            time_start: timeStart ? timeStart.valueOf() / 1000 : '',
            time_stop: timeStop ? timeStop.valueOf() / 1000 : '',
            waypoints: waypts
        };

  const handleGenerateTable = () => {
      const startDateObj = new Date(timeStart);
    const endDateObj = new Date(timeStop);

    // Вычисляем разницу в днях между датами
    const timeDiff = Math.abs(endDateObj - startDateObj);
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    // Генерируем данные для таблицы
    const generatedData = [];
    for (let i = 0; i <= daysDiff; i++) {
      const currentDate = new Date(startDateObj);
      currentDate.setDate(startDateObj.getDate() + i);
      generatedData.push(currentDate.toDateString());
    }

    // Устанавливаем данные в состояние
    setTableData(generatedData);
      setShowTable(true);
      setRowCount(0);
    // Генерируйте таблицу на основе введенных данных (name, timeZone, timeStart, timeStop, locations)
  };

const handleAddRow = () => {
    setRowCount(rowCount + 1);
  };

useEffect(() => {
    // Этот блок кода выполнится после обновления переменной myData
    if (shouldRequestSent && waypts !== []) {
      // Отправить запрос или выполнить действие с myData
      fetch('http://box-dev.dvlb.ru:8081/plans/create', {
              method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": 'http://box-dev.dvlb.ru:8081',
                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            },
            body: JSON.stringify(planData),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setWaypts([]);
            })
            .catch(error => {
                console.log(error);
            });
    }
    setshouldRequestSent(false);
  }, [waypts, shouldRequestSent]); // Указываем myData как зависимость

const handleSend = () => {
    for (let i= 0; i < rowCount; i++) {
          let users = document.getElementById(i.toString() + "users").value;
          let eventStart = document.getElementById(i.toString() + "eventstart").value;
          eventStart = timeToSeconds(eventStart);
          let eventStop = document.getElementById(i.toString() + "eventstop").value;
          eventStop = timeToSeconds(eventStop);
          let dates = [];
          for (let j = 0; j < tableData.length; j++) {
              if (document.getElementById(i.toString() + j.toString()).checked) {
                  dates.push(parseInt(Date.parse(tableData[j]))/1000);
              }
          }
          let item = {
            location: document.getElementById(i.toString() + "loc").value,
            users: users.split(','),
            dates: dates,
            groups: [],
            start: parseInt(eventStart),
            finish: parseInt(eventStop),
        };
        setWaypts([...waypts, item]);
        setshouldRequestSent(true);
    }


}

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

function timeToSeconds(time) {
          const [hours, minutes] = time.split(':');
          return (+hours * 3600) + (+minutes * 60);
        }

  return (
      <div>
          <h1 className={'planer-header'}>Регистрация планов</h1>
    <Container className={'matcher-container'}>
      <TextField
        label="Название плана"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <FormControl sx={{ width: '225px' }}>
        <InputLabel id="demo-simple-select-label">Временная зона</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Временная зона:"
          value={timeZone}
          onChange={(event) => setTimeZone(event.target.value)}
        >
          <MenuItem value={'UTC+2'}>Калининград</MenuItem>
          <MenuItem value={'UTC+3'}>Москва</MenuItem>
          <MenuItem value={'UTC+4'}>Самара</MenuItem>
          <MenuItem value={'UTC+5'}>Екатеринбург</MenuItem>
          <MenuItem value={'UTC+6'}>Омск</MenuItem>
          <MenuItem value={'UTC+7'}>Красноярск</MenuItem>
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Дата начала плана"
          value={timeStart}
          onChange={(date) => setTimeStart(date)}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label="Дата конца плана"
          value={timeStop}
          onChange={(date) => setTimeStop(date)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <Button variant="contained" onClick={handleGenerateTable} className={'generate-plan-btn'} sx={{marginTop: '10px', marginLeft:'10px'}}>
        Генерировать таблицу
      </Button>
        {showTable && tableData.length > 0 && (<TableContainer sx={{marginTop: '10px', marginLeft: '0'}}>
        <Table>
          <TableHead>
            <TableRow>
                <th className={'planer-cell'}><Button variant="contained" onClick={handleAddRow} sx={{marginLeft: '-13px'}}>
                    Добавить локацию
                  </Button></th>
                <th className={'planer-cell'}>Список пользователей</th>
                <th className={'planer-cell'}>Начало события</th>
                <th className={'planer-cell'}>Конец события</th>
              {tableData.map((dateString, index) => (
                <th key={index} className={'planer-cell'}>{dateString} </th>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
              {[...Array(rowCount)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                <td className={'planer-cell'}><TextField id={rowIndex.toString()+"loc"}
                    label="ID локации"
                  /></td>
                  <td className={'planer-cell'}><TextField
                        label="Пользователи"
                        id={rowIndex.toString()+"users"}
                      /></td>
                  <td className={'planer-cell'}> <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                          ampm={false}
                      label="Начало события"
                      // value={eventStart}
                      defaultValue={dayjs('2022-04-17T14:30')}
                      slotProps={{textField: {
                          id: rowIndex.toString()+"eventstart"
                          }
                      }
                      }

                  /></LocalizationProvider></td>
                  <td className={'planer-cell'}><LocalizationProvider dateAdapter={AdapterDayjs}><TimePicker
                          label="Окончание события"
                          ampm={false}
                          defaultValue={dayjs('2022-04-17T15:30')}
                          // value={eventStop}
                      slotProps={{textField: {
                          id: rowIndex.toString()+"eventstop"
                          }
                      }
                      }
                  /></LocalizationProvider></td>
                {tableData.map((e, columnIndex) => (
                    // <td key={columnIndex} id={e}><Button variant="contained" onClick={handleOpenModal}>
                    //     Назначить событие
                    //   </Button></td>
                    <td key={columnIndex} className={'planer-cell'}>
                        <Checkbox id={rowIndex.toString() + columnIndex.toString()}>
                        <div>Назначить событие</div>
                      </Checkbox></td>
                ))}
              </tr>
            ))}
          </TableBody>
        </Table>
      </TableContainer>)}
        <Button variant="contained" onClick={handleSend} sx={{marginTop: '10px', marginLeft: '10px'}}>
                    Отправить
          </Button>
      {/*<Modal open={modalOpen} onClose={handleCloseModal}>*/}
      {/*  <Container>*/}
      {/*<Typography variant="h6">Назначить событие</Typography>*/}
      {/*    <TextField*/}
      {/*      label="ID локации"*/}
      {/*      value={locID}*/}
      {/*      onChange={(e) => setLocID(e.target.value)}*/}
      {/*    />*/}
      {/*    <TextField*/}
      {/*      label="Пользователи"*/}
      {/*      value={users}*/}
      {/*      onChange={(e) => setUsers(e.target.value)}*/}
      {/*    />*/}
      {/*    <LocalizationProvider dateAdapter={AdapterDayjs}>*/}
      {/*      <TimePicker*/}
      {/*        label="Начало события"*/}
      {/*        value={eventStart}*/}
      {/*        onChange={(date) => setEventStart(date)}*/}
      {/*        renderInput={(params) => <TextField {...params} />}*/}
      {/*      />*/}
      {/*      <TimePicker*/}
      {/*        label="Конец события"*/}
      {/*        value={eventStop}*/}
      {/*        onChange={(date) => setEventStop(date)}*/}
      {/*        renderInput={(params) => <TextField {...params} />}*/}
      {/*      />*/}
      {/*    </LocalizationProvider>*/}
      {/*    <Button variant="contained" onClick={handleAssignEvent}>*/}
      {/*      Назначить событие*/}
      {/*    </Button>*/}
      {/*  </Container>*/}
      {/*</Modal>*/}
    </Container>
      </div>
  );
}

export default PlanForm;